import { createConsumer } from "@rails/actioncable"

var url = ""

if (window.location.hostname == 'localhost') {
    url = 'ws://localhost:3000/cable'
}else{
    url = `wss://${window.location.hostname}/cable`
}

const consumer = createConsumer(url)
export default consumer