import consumer from "./consumer"

export const ValidateChannel =  consumer.subscriptions.create("ValidateChannel", {
    connected() {
        console.log("Connected to ChatChannel");
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {

        var data_errors = Object.values(data.data_errors)
        var keys_errors = Object.keys(data.data_errors)
        var html = ""
        var _t = []
        _t['cid'] = "CID"
        _t['tipo_de_atestado'] = "Tipo de Atestado"
        _t['cnpj'] = "CNPJ"
        _t['photos'] = "Foto"
        _t['numero_de_registro'] = "Número de Registro"
        _t['tempo_de_dispensa'] = "Tempo de dispensa"
        _t['examinador_numero_registro'] = "Número de Registro do Examinador"
        _t['exames'] = "Exames"
        _t['exames_complementares'] = "Exames Complementares"
        _t['descricao_do_afastamento'] = "Descrição do Afastamento"
        _t['examinador_registro'] = "Examinador"
        _t['nome_funcionario'] = "Funcionário"

        _t['funcionario_okay'] = "Certificar Funcionário"
        _t['empresa_okay'] = "Certificar Empresa"


        data_errors.map((er, index) => {
            if (keys_errors[index] != "funcionario_id" && er.length > 0){
                html += `<li class='text-sm text-red-600'><strong>${_t[keys_errors[index]]}:</strong> ${er} </li>`
            }
        })

        if(html.length > 0) {
            $("#area-errors")
                .html(`<div class="bg-gray-100 border border-red-400 text-red-700 rounded relative" role="alert"><div class="m-4">${html}</div></div>`)
        }else{
            $("#area-errors").html("")
        }

        if(data.field == "tempo_de_dispensa"){
            $('tipo_de_atestados_error').remove()

            $(`[name="atestado[tipo_de_atestado]"]`).removeClass('is-valid')
            $(`[name="atestado[tipo_de_atestado]"]`).parent().removeClass('is-valid')

        }

        var repeat = 0;
        data_errors.map((er) => {
            if(data.error.length > 0){
                $(`#${data.field}_error`).remove()

                $(`[name="atestado[${data.field}]"]`).removeClass('is-valid')
                $(`[name="atestado[${data.field}]"]`).parent().removeClass('is-valid')

                // $(`[name="atestado[${data.field}]"]`)
                //     .addClass('input-error')
                //     .after(`<span id="${data.field}_error" class='text-sm text-red-600'>${data.error.join(' | ')}</span>`)
            }else{
                if(data.field == "photos"){

                    var css = {
                        "border-color": "#28a745",
                        "padding-right": "calc(1.5em + .75rem)",
                        "background-image": 'url("data:image/svg+xml,%3csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\' viewBox=\'0 0 8 8\'%3e%3cpath fill=\'%2328a745\' d=\'M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z\'/%3e%3c/svg%3e")',
                        "background-repeat": "no-repeat",
                        "background-position": "right calc(.375em + .1875rem) center",
                        "background-size": "calc(.75em + .375rem) calc(.75em + .375rem)"
                    };

                    if (repeat == 0){
                        $(".atestado_photos")
                            .parent()
                            .after('<div></div>')
                            .css(css)
                    }
                    repeat++
                }


                $(`[name="atestado[${data.field}]"]`)
                    .removeClass('input-error')
                $(`#${data.field}_error`).remove()

                $(`[name="atestado[${data.field}]"]`).addClass('is-valid')
                $(`[name="atestado[${data.field}]"]`).parent().addClass('is-valid')
            }
        })


        if(data.ability_btn){
            $('#btn-submit').prop('disabled', false)
        }
        console.log("Received data:", data);
    },

    sendMessage: function(field, data) {
        this.perform('send_message', {
            field: field,
            data: data
        });
    }
});



var result = {}

var fields_select = [
    "funcionario_id",
    "tipo_de_atestado",
    "tipo_de_registro",
    "descricao_do_afastamento",
    "examinador_registro",
    "exames",
    "exames_complementares",
]

var fields_checked = [
    "empresa_okay",
    "funcionario_okay"
]

var fields_input = [
    "numero_de_registro",
    "cnpj",
    "tempo_de_dispensa",
    "cid",
    "uid",
    "origem",
    "examinador_numero_registro",
    "instituicao_de_saude"
]

var fields_load = [
    "tipo_de_atestado",
    "tipo_de_registro",
]

export const get_values = (field) => {
    let origem = $('[name="atestado[origem]"]').val()
    let ta = $('[name="atestado[tipo_de_atestado]"]').val()
    let funcionario_okay = $('[name="atestado[funcionario_okay]"]').is(':checked');
    let empresa_okay = $('[name="atestado[empresa_okay]"]').is(':checked');

    let uuid =  $('#uuid').val()

    let obj = {};
    obj['origem'] = origem;
    obj['uuid'] = uuid;
    obj['tipo_de_atestado'] = ta;
    obj['funcionario_okay'] = funcionario_okay;
    obj['empresa_okay'] = empresa_okay;
    result = {...result, ...obj}


    if(!['funcionario_okay', 'empresa_okay'].includes(field)) {

        let value = $(`[name="atestado[${field}]"]`).val()
        obj[field] = value;
        result = {...result, ...obj}
    }

    ValidateChannel.sendMessage(field, result)
}


document.addEventListener("turbolinks:load", () => {

    fields_select.map((field) => {
        $(`[name="atestado[${field}]"]`).change(() => get_values(field))
    })

    fields_checked.map((field) => {
        $(`[name="atestado[${field}]"]`).change(() => get_values(field))
    })

    fields_input.map((field) => {
        $(`[name="atestado[${field}]"]`).blur(() => get_values(field))
    })

    fields_load.map((field) => {
        $(`[name="atestado[${field}]"]`).addClass('is-valid')
        $(`[name="atestado[${field}]"]`).parent().addClass('is-valid')
    })

    $("#file-input").change(function() {
        let obj = {};
        obj['photos'] = true;
        obj['funcionario_okay'] = true;
        result = {...result, ...obj}
        ValidateChannel.sendMessage("photos", result);
    });

})
